<template>
  <div>
    <title-nav :title="'상품 등록/관리'" :nav="'상품  관리 > 등록/관리'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <!-- 수강권 / All in One 구독권 / 주식클래스 구독권 / 종목클래스 구독권 -->
          <b-form-radio-group
            v-model="current_type"
            :options="productOptions"
            value-field="cd_value"
            class="mb-3"
            disabled-field="notEnabled"
            @change="changeRadio"
            @input="inputRadio($event)"
          ></b-form-radio-group>
          <b-row>
            <!-- 이용기간/이용개수 -->
            <b-col sm="3"
              ><label>{{ voucherLabel }}</label></b-col
            >
            <b-col sm="9">
              <b-form-input v-model="product_cnt" @input="inputVoucherCnt" />
              <!-- <b-form-input
                v-model="product_cnt"3
                @update="inputVoucherCnt"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              /> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3"><label>상품명</label></b-col>
            <b-col>
              <b-form-input v-model="product_name" id="product_name"></b-form-input>
              <!-- <input type="text" v-model="product_name" class="form-control" /> -->
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="3"><label>인앱타임(WEB/AOS/IOS)</label></b-col>
            <b-col class="row align-items-center">
              <input type="radio" id="one" value="WEB" v-model="in_app_type" /><label class="mr-3">
                WEB</label
              >
              <input type="radio" id="one" value="AOS" v-model="in_app_type" /><label class="mr-3">
                안드로이드</label
              >
              <input
                type="radio"
                id="is_inapp3"
                value="IOS"
                v-model="in_app_type"
                form="is_inapp3"
              /><label class="mr-3"> iOS</label>

              <!-- <input type="text" v-model="product_name" class="form-control" /> -->
            </b-col>
          </b-row>
          <b-row v-if="in_app_type !== 'WEB'">
            <b-col sm="3"><label>상품 ID</label></b-col>
            <b-col>
              <b-form-input
                v-model="inapp_id"
                id="inapp_id"
                placeholder="play console 혹은 App Center에 등록한 제품.id, 상품ID (ex:kr.co.fmschool.dev.product.##)"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="3"><label>상품 가격</label></b-col>
            <b-col
              ><b-input-group append="원">
                <b-form-input
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  v-model="price"
                  :formatter="currency"
                ></b-form-input> </b-input-group
            ></b-col>
          </b-row>
          <b-row>
            <b-col sm="3"><label>판매 가격</label></b-col>
            <b-col
              ><b-input-group append="원">
                <b-form-input
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  v-model="net_price"
                  :formatter="currency"
                ></b-form-input></b-input-group
            ></b-col>
          </b-row>
          <b-row>
            <b-col sm="3"><label>할인율</label></b-col>
            <b-col
              ><b-input-group append="%">
                <b-form-input v-model="disc_per" readonly></b-form-input> </b-input-group
            ></b-col>
          </b-row>
          <b-row>
            <b-col><label>상품설명</label></b-col>
            <b-col> <editor ref="content_editor" :contents="contents"/></b-col>
          </b-row>

          <b-row>
            <template v-if="isEditMode">
              <b-button class="m-1" variant="dark" @click.prevent="cancel">초기화</b-button>
              <b-button class="m-1" variant="success" @click.prevent="editContent"
                >수정 등록</b-button
              >
            </template>
            <template v-else>
              <b-button class="m-1" variant="dark" @click.prevent="cancel">취소</b-button>
              <b-button class="m-1" variant="primary" @click.prevent="add">판매 등록</b-button>
            </template>
          </b-row>
        </div>
        <div class="card-body ml-1">
          <b-container class="row">
            <b-row
              ><b-col>
                <b-form-group
                  label="판매여부 필터링"
                  v-slot="{ ariaDescribedby }"
                  class="align-items-left"
                >
                  <b-row class="row">
                    <b-form-radio
                      v-model="radioFilterOnSale"
                      :aria-describedby="ariaDescribedby"
                      name="sale-radios"
                      value="ALL"
                      class="pr-1"
                      >모든 상품
                    </b-form-radio>
                    <b-form-radio
                      v-model="radioFilterOnSale"
                      :aria-describedby="ariaDescribedby"
                      name="sale-radios"
                      value="SALE"
                      class="pr-1"
                      >판매중
                    </b-form-radio>
                    <b-form-radio
                      v-model="radioFilterOnSale"
                      :aria-describedby="ariaDescribedby"
                      name="sale-radios"
                      value="SALE_STOP"
                      class="pr-1"
                      >판매중지
                    </b-form-radio>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="판매/인앱 사이트 필터링" v-slot="{ ariaDescribedby }" class="">
                  <b-row class=" ">
                    <b-form-radio
                      v-model="radioFilterOSProduct"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="RADIO_ALL_PRODUCT_TYPE"
                      class="pr-1"
                      >전체 판매 사이트</b-form-radio
                    >
                    <b-form-radio
                      v-model="radioFilterOSProduct"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="RADIO_WEB_PRODUCT_TYPE"
                      class="pr-1"
                      >WEB</b-form-radio
                    >
                    <b-form-radio
                      v-model="radioFilterOSProduct"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="RADIO_AOS_PRODUCT_TYPE"
                      class="pr-1"
                      >안드로이드</b-form-radio
                    >
                    <b-form-radio
                      v-model="radioFilterOSProduct"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="RADIO_IOS_PRODUCT_TYPE"
                      class="pr-1"
                      >애플</b-form-radio
                    >
                  </b-row>
                </b-form-group>
              </b-col></b-row
            >
          </b-container>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="filterOnSaleData"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <template v-slot:cell(reg_dt)="data">{{ data.value | dateFilter }}</template>
            <template v-slot:cell(price)="data">{{ data.value | commaFilter }}</template>
            <template v-slot:cell(net_price)="data">{{ data.value | commaFilter }}</template>
            <template v-slot:cell(pr_type)="data">{{ getCodeTitle(data.value) }}</template>
            <template v-slot:cell(disc_per)="data">{{ `${data.value}%` }}</template>
            <template v-slot:cell(pr_stat_cd)="data">
              <template v-if="data.value === 'HIDDEN'">
                <b-button variant="outline-warning" @click="showStatus('HIDDEN', data.item)"
                  >판매 중지
                </b-button>
              </template>
              <template v-else>
                <b-button variant="outline-danger" @click="showStatus('NORMAL', data.item)"
                  >판매중
                </b-button>
              </template>
            </template>
            <template v-slot:cell(custom_edit)="data">
              <b-button variant="link" @click="fillData(data.item)"> 수정 </b-button>
            </template>
          </b-table>
        </div>
      </div>
    </b-container>
    <template>
      <!-- 강좌 상태 변경 -->
      <b-modal ref="modal-status" hide-footer title="상태 변경">
        <br />
        <div>선택한 상품명 : {{ selected_voucher.title }}</div>
        <div>
          현재 상태 : {{ selected_voucher.pr_stat_cd === 'HIDDEN' ? '판매중지' : '판매중' }}
        </div>
        <br />
        <br />
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click="setStatusTo('NORMAL', selected_voucher.pr_sn)"
          >'판매중' 으로 설정</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-warning"
          block
          @click="setStatusTo('HIDDEN', selected_voucher.pr_sn)"
          >'판매 중지' 으로 설정</b-button
        >
      </b-modal>
    </template>
  </div>
</template>

<script>
import { createVoucher, getProducts, editVoucher, editVoucherState } from '../../api/voucher'
import { getVoucherType } from '../../api/code'
import Editor from '../util/Editor'
export default {
  name: 'voucher',
  components: { Editor },
  data() {
    return {
      product_name: '',
      tab_index: 0,
      product_cnt: '' /**구독권, 수강권 이용횟수 이용일수 */,
      VOUCHER_CODE: [],
      current_type: '' /**01, 02, 03 or 04 */,
      price: '',
      net_price: '',
      items: [],
      tbItems: [],
      isBusy: false,
      headVariant: 'dark',
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      noCollapse: false,
      fixed: false,
      voucherLabel: '이용개수/이용기간',
      inapp_id: '',
      in_app_type: 'WEB',
      fields: [
        { key: 'pr_sn', label: 'IDX', colType: 'span' },
        { key: 'reg_dt', label: '등록일시', colType: 'span' },
        { key: 'pr_type', label: '상품타입', colType: 'span' },
        { key: 'title', label: '상품명', colType: 'span' },
        { key: 'price', label: '상품가격', colType: 'span' },
        { key: 'net_price', label: '판매가격', colType: 'span' },
        { key: 'disc_per', label: '할인율(%)', colType: 'span' },
        { key: 'pr_stat_cd', label: '상태/변경', colType: 'span' },
        { key: 'custom_edit', label: '상태/수정', colType: 'span' },
        { key: 'in_app_id', label: '인앱ID', colType: 'span' },
        { key: 'in_app_type', label: '인앱타입', colType: 'span' }
      ],
      selectProduct: '선택하세요',
      isEditMode: false,
      selected_sn: 0,
      selected_voucher: '',
      productOptions: [],
      contents: '' /** editor 내용 */,
      radioFilterOnSale: 'ALL' /**ALL, SALE, SALE_STOP */,
      radioFilterOSProduct:
        'RADIO_ALL_PRODUCT_TYPE' /** RADIO_ALL_PRODUCT_TYPE, RADIO_WEB_PRODUCT_TYPE,RADIO_AOS_PRODUCT_TYPE,RADIO_IOS_PRODUCT_TYPE */
    }
  },
  watch: {
    current_type: function() {
      this.product_cnt = ''
      this.product_name = ''
    }
  },
  computed: {
    disc_per: function() {
      if (this.net_price && this.price) {
        console.log(`disc_per`)
        var net_price1 = Number(this.net_price.replace(/,/g, '')) /**상품가격 */
        var temp_price = Number(this.price.replace(/,/g, '')) /**판매가격 */
        return ((temp_price - net_price1) / temp_price) * 100
      } else return 0
    },
    filterOnSaleData: function() {
      console.log('filter on sale')
      let items = this.tbItems
      if (this.radioFilterOnSale == 'ALL') {
      } else if (this.radioFilterOnSale == 'SALE') {
        items = this.tbItems.filter(item => item.pr_stat_cd == 'NORMAL')
      } else if (this.radioFilterOnSale == 'SALE_STOP') {
        items = this.tbItems.filter(item => item.pr_stat_cd !== 'NORMAL')
      }

      console.log('this.radioFilterOSProduct : ', this.radioFilterOSProduct, items)
      if (this.radioFilterOSProduct == 'RADIO_AOS_PRODUCT_TYPE') {
        items = items.filter(item => item.in_app_type == 'AOS')
      } else if (this.radioFilterOSProduct == 'RADIO_IOS_PRODUCT_TYPE') {
        items = items.filter(item => item.in_app_type == 'IOS')
      } else if (this.radioFilterOSProduct == 'RADIO_WEB_PRODUCT_TYPE') {
        items = items.filter(item => {
          return item.in_app_type == '' || item.in_app_type == null || item.in_app_type == 'WEB'
        })
      }
      return items
    },
    filterOSProductData: function() {}
    /**
     * Radio 그룹에서 선택한것이 구독이면 return true 아니면 false
     */
    // isSubscribe: function() {
    //   const selectedData = this.VOUCHER_CODE.find(value => value.cd_value === this.current_type)
    //   // console.log(`seelctedData:${JSON.stringify(selectedData)}`)
    //   if (selectedData) {
    //     this.prepend_title = selectedData?.cd_name_kr
    //     console.log(selectedData?.cd_name_kr.includes('구독'))
    //     return selectedData?.cd_name_kr.includes('구독')
    //   }
    //   return false
    // }
  },
  methods: {
    clearData() {
      this.current_type = null
      this.product_name = null
      this.price = null
      this.net_price = null
      this.contents = ''
    },
    currency(value) {
      var t = value.toString()
      if (t.length > 0) return t.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      else return ''
    },
    async setStatusTo(pr_stat_cd, pr_sn) {
      try {
        const resp = await editVoucherState(pr_sn, pr_stat_cd)
        console.log(resp)
        if (resp.code === '0000') {
          this.getProducts()

          alert(`반영되었습니다`)
          this.$refs['modal-status'].hide()
        } else {
          alert(resp.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    showStatus(current_status, item) {
      console.log(current_status)
      this.selected_voucher = item
      this.$refs['modal-status'].show()
    },
    /**
     * 수정을 위해 상품 선택시 form에 데이터 채우는 함수
     */
    fillData(item) {
      this.cancel()
      console.log(`select item:${JSON.stringify(item)}`)

      var type = this.getCodeTitle(item.pr_type)

      //동기화가 느리게 되기 때문에
      this.$nextTick(() => {
        this.current_type = this.getCode(type)
        this.$nextTick(() => {
          this.product_name = item.title
          // this.inputVoucherCnt(this.current_type)
        })
      })

      this.product_cnt = item.title.replace(/^\D+/, '')
      this.net_price = this.currency(item.net_price)
      this.price = this.currency(item.price)
      this.isEditMode = true
      this.selected_sn = item.pr_sn
      this.contents = item.contents
      this.inapp_id = item.in_app_id
      this.in_app_type = item.in_app_type

      console.log('item is', item)
    },
    async editContent() {
      const resp = await editVoucher(
        this.selected_sn,
        this.current_type,
        this.product_cnt,
        `${this.product_name}`,
        this.price,
        this.net_price,
        this.disc_per,
        this.$refs.content_editor.editor.getHTML(),
        this.inapp_id,
        this.in_app_type
      )
      if (resp.code !== '0000') {
        alert(resp.message)
      } else {
        this.getProducts()
        this.clearData()
        alert('등록되었습니다')
      }
    },
    getCode(type) {
      // type is 수강권 or 구독권
      const code = this.VOUCHER_CODE.find(value => value.cd_name_kr === type).cd_value
      return code
    },
    getCodeTitle(code) {
      /** code is '01' */

      if (code) {
        var name = this.VOUCHER_CODE.find(value => value.cd_value === code)
        if (name) return name.cd_name_kr

        return `code not match(${code})`
      } else {
        return ''
      }
    },
    async creatVoucherType() {
      var resp = await getVoucherType()
      this.VOUCHER_CODE = resp
      console.log('resp.data is ', resp)
      resp.forEach((item, index) => {
        if (index !== 0) {
          this.productOptions.push({
            text: item.cd_name_kr,
            value: item.cd_value,
            cd_value: item.cd_value
          })
        }
      })
    },
    /**상품명 입력 */
    inputVoucherCnt(value) {
      this.product_name = `${this.getCodeTitle(this.current_type)} ${value}`
      console.log(`inputVoucherCnt ~  this.product_name(${this.product_name}) `)
    },
    changeRadio() {
      var selected = this.productOptions.find(item => item.cd_value == this.current_type)

      if (selected.text.includes('수강')) {
        this.voucherLabel = '사용일수'
        this.product_name = selected.text
      } else {
        this.voucherLabel = '이용기간'
      }
    },
    inputRadio(value) {
      console.log(value)
    },
    cancel() {
      this.clearData()
    },
    async add() {
      const resp = await createVoucher(
        this.current_type,
        this.product_cnt,
        `${this.product_name}`,
        this.price,
        this.net_price,
        this.disc_per,
        this.$refs.content_editor.editor.getHTML(),
        this.inapp_id,
        this.in_app_type
      )
      if (resp.code !== '0000') {
        alert(resp.message)
      } else {
        alert('등록되었습니다')
        this.getProducts()
        this.clearData()
      }
    },
    async getProducts() {
      const resp = await getProducts()
      if (resp.code === '0000') {
        const data1 = resp.data.filter(e => e.pr_stat_cd === 'NORMAL')
        const data2 = resp.data.filter(e => e.pr_stat_cd !== 'NORMAL')
        console.log(data1, data2)
        this.tbItems = []
        this.tbItems.push(...data1)
        this.tbItems.push(...data2)
      }
    }
  },
  mounted() {
    this.creatVoucherType()
    this.getProducts()
  }
}
</script>
